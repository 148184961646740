var supOrderListPage = function () {
    var scope = ".js-sup-invoice-list-page";

    function getItemId($item, name) {
        var url = $item.find("a").eq(0).attr("href").substring(1);
        if (!url) url = location.href;
        name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
        var regexS = "[\\?&]" + name + "=([^&#]*)";
        var regex = new RegExp(regexS);
        var results = regex.exec(url);
        return results == null ? null : results[1];
    }

    ui.ready(
        scope,
        function ($page) {

            $page.find(scope+"__markaspaid").click(function(ev){
                ev.preventDefault();
                var method = $page.find(scope+"__method")[0].value;
                    swal({
                        title:"¿Confirmar pago de las facturas?",
                        text:"Debe seleccionar las instrucciones para la notificación y sólo las facturas que se encuentren validadas confirmarán su pago. Esto también notificará el pago de las facturas al proveedor",
                        type:"info",
                        showCancelButton: true,
                        confirmButtonColor: "#00bb00",
                        confirmButtonText: "Aprobar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true,
                        showLoaderOnConfirm: true},
                        function(isConfirm){
                        var selectedIds2 = [];
                        $("input[type='checkbox']:checked").closest(".cp-simple-list-page__tr").each(function(){
                                selectedIds2.push(getItemId($(this),'id'));
                            });

                            if(isConfirm){
                                gvf.endpoint(
                                    "controller\\cp\\supInvoiceListController::markaspaid",
                                    [selectedIds2,method],
                                    function(resp){
                                        console.log(resp);
                                        utils.alertEndpointResponse(resp);
                                        if(resp.status == "ok"){
                                            window.location.reload();
                                        }

                                    }
                                );
                            }
                        });


            });

            $page.find(scope+"__massive-payment").click(
                function (ev) {
                    ev.preventDefault();
                    detailPage.openModal(
                        "SupInvoiceMassivePayment",
                        null,
                        true,
                        null,
                        null,
                        {
                            initContent:function(modal,data){
                                supInvoiceMassivePaymentDetailPage.initSupInvoiceMassivePaymentDetailPage(modal,data)
                            },
                            modalListTemplate:'cp/sup-invoice/sup-invoice-massive-payment-detail-page.twig',
                            title:'Pago másivo de facturas',
                            additionalData: {}
                        },
                        null
                    );
                }

            );

            $page.find(scope + "__send-invoice").click(
                function (ev) {
                    ev.preventDefault();
                    ev.stopPropagation();
                    page.showLoading();
                    var $button = $(this);
                    $button.prop("disabled", true);
                    gvf.endpoint(
                        "controller\\cp\\supInvoiceListController::sendInvoice",
                        [$button.data("id")],
                        function (resp) {
                            page.hideLoading();
                            $button.prop("disabled", false);
                            if (resp.status == "ok") {
                                window.location.reload();
                            } else
                                utils.alertEndpointResponse(resp);
                        }
                    );
                }
            );
        }
    );
}();

