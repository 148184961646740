var supInvoiceMassivePaymentDetailPage = function(){
    var scope = ".js-cus-offer-quality-control-detail-page";

    function initSupInvoiceMassivePaymentDetailPage($modal,$data){
        $modal.find(".js-cus-offer-quality-control-detail-page__init-payments").click(
            function(ev){
                ev.preventDefault();
                var repfile  = $modal.find("[name='rep_file_id']").val();
                if(repfile == ""){
                    utils.alertEndpointResponse({status:"error",message:"Debe subir un archivo"});
                } else {
                    gvf.endpoint(
                        "controller\\cp\\supInvoiceMassivePaymentDetailController::initPayments",
                        [repfile],
                        function(resp){
                            utils.alertEndpointResponse(resp);
                            if(resp.status == "ok"){
                                $modal.modal("hide");
                                window.location.reload();
                            }
                        }
                    )
                }

            }
        )







    }

    return{
       initSupInvoiceMassivePaymentDetailPage:initSupInvoiceMassivePaymentDetailPage
    }
}();
